import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowLeft, Info, Save, ArrowRight } from "lucide-react";

import { useEffect, useState } from "react";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Textarea } from "../ui/TextArea";
import { Label } from "../ui/Label";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";

import { createSign, clearSingleSign } from "../../actions/sign";
import { plans } from "../../utlis/plans";
import { updatePlan } from "../../actions/stripe";

import AuthMenu from "../layout/AuthMenu";
import { setAlert } from "../../actions/alert";

const CreateNewCodePage = ({
  createSign,
  clearSingleSign,
  singleSign,
  saving,
  user,
  codes,
  updatePlan,
  setAlert,
}) => {
  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    clearSingleSign();
  }, [clearSingleSign]);

  const [code, setCode] = useState({
    code: "",
    message: "",
    forward: "",
    notify: "",
    address: "",
  });

  const [planUpgrade, setPlanUpgrade] = useState(false);
  const filterPlanById = (planId, plansList) => {
    return plansList.find((plan) => plan.plan === planId) || null;
  };
  const nextHigherPlan = (codes, time, plansList) => {
    return plansList.find(
      (plan) => plan.minCodes === codes + 1 && plan.time === time
    );
  };

  const [plan, setPlan] = useState(null);
  const [upgrade, setUpgrade] = useState(null);
  const [needsBilling, setNeedsBilling] = useState(false);
  useEffect(() => {
    if (user.plan) {
      if (user.plan === "free") {
        setAlert(
          "You need to set up your billing before you can create a code",
          "warning"
        );
        return setNeedsBilling(true);
      }
      const userPlan = filterPlanById(user.plan, plans);
      console.log(userPlan);
      setPlan(userPlan);
      if (userPlan.codes === codes.length) {
        const up = nextHigherPlan(userPlan.codes, userPlan.time, plans);
        setUpgrade(up);
        setPlanUpgrade(true);
      }
    }
  }, [user, codes, setAlert]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCode((prevCode) => ({
      ...prevCode,
      [name]: value,
    }));
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setCode({ ...code, forward: phone });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (planUpgrade) {
      //upgrade to next plan before saving the code
      updatePlan(upgrade.plan);
    }
    createSign({
      code: code.code,
      message: code.message,
      notify: code.notify,
      forward: code.forward,
      address: code.address,
    });
  };

  if (singleSign && !saving) {
    return <Navigate to={`/codes/${singleSign._id}`} />;
  }

  if (needsBilling) {
    return <Navigate to={`/billing`} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
            onClick={() => handleRedirect("/codes")}
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Codes
          </Button>
          <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200">
            Create New Code
          </h2>
        </div>

        <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
          {planUpgrade && (
            <div className="mb-6 p-4 bg-blue-50 dark:bg-blue-900 rounded-lg shadow-md">
              <h3 className="text-lg font-bold text-blue-600 dark:text-blue-300 mb-4">
                Upgrade Required to Create More Codes
              </h3>
              <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                {/* Current Plan */}
                <div className="flex-1 bg-white dark:bg-gray-800 rounded-md p-4 shadow-md">
                  <h4 className="text-gray-800 dark:text-gray-200 font-semibold text-sm">
                    Current Plan
                  </h4>
                  <p className="text-xl font-bold text-gray-900 dark:text-gray-100">
                    ${plan.price} / month
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {plan.codes} codes included
                  </p>
                </div>

                <ArrowRight className="h-6 w-6 text-blue-500 dark:text-blue-300" />

                {/* Upgrade Plan */}
                <div className="flex-1 bg-green-50 dark:bg-green-900 rounded-md p-4 shadow-md">
                  <h4 className="text-green-600 dark:text-green-300 font-semibold text-sm">
                    Next Plan
                  </h4>
                  <p className="text-xl font-bold text-green-800 dark:text-green-100">
                    ${upgrade.price} / month
                  </p>
                  <p className="text-sm text-green-600 dark:text-green-400">
                    {upgrade.codes} codes included
                  </p>
                </div>
              </div>

              <p className="mt-4 text-sm text-gray-800 dark:text-gray-400">
                By creating a new code, you’ll move to a higher plan with more
                codes. Your subscription cost will increase from{" "}
                <span className="font-semibold">${plan.price}</span> to{" "}
                <span className="font-semibold">${upgrade.price}</span>{" "}
                {plan.time}.
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        You can also delete any unused codes to not have to
                        upgrade. If you ever delete enough codes to move down
                        plans, we'll downgrade your plan automatically.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </p>
              {/* <Button
                  className="mt-4 bg-green-600 hover:bg-green-700 text-white"
                  onClick={() => handleRedirect("/plans")}
                >
                  Learn More About Plans
                </Button> */}
            </div>
          )}
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
              Code Details
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="code" className="flex items-center">
                  Code
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 ml-2 text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          Enter a unique identifier for this code. Use something
                          memorable like "MAINST" or "BEACHHOUSE".
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <Input
                  id="code"
                  name="code"
                  value={code.code}
                  onChange={handleInputChange}
                  placeholder="e.g., MAINST"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="message" className="flex items-center">
                  Response Message
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 ml-2 text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          This message will be sent when someone texts your
                          code. Include property details or call-to-action.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <Textarea
                  id="message"
                  name="message"
                  value={code.message}
                  onChange={handleInputChange}
                  className="min-h-[100px]"
                  placeholder="Thank you for your interest in 123 Main St. A representative will contact you shortly."
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="forward" className="flex items-center">
                  Forward Phone Number
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 ml-2 text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          If someone calls and then enters this code (works for
                          numerical cods only), calls will be forwarded to this
                          number.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <Input
                  id="forward"
                  name="forward"
                  value={code.forward}
                  onChange={formatPhone}
                  placeholder="e.g., 555-123-4567"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="notify" className="flex items-center">
                  Email Notification
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 ml-2 text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          You'll receive an email at this address when someone
                          uses your code.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <Input
                  id="notify"
                  name="notify"
                  type="email"
                  value={code.notify}
                  onChange={handleInputChange}
                  placeholder="e.g., agent@example.com"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="address" className="flex items-center">
                  Tracking Code
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-4 w-4 ml-2 text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          Optional: Use this to track specific campaigns or
                          properties in your reports.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <Input
                  id="address"
                  name="address"
                  value={code.address}
                  onChange={handleInputChange}
                  placeholder="e.g., Spring2023 or 123MainSt"
                />
              </div>

              <Button onClick={handleSave} className="w-full">
                <Save className="mr-2 h-4 w-4" /> Create Code{" "}
                {planUpgrade && <>& Upgrade Plan</>}
              </Button>
            </form>
          </CardContent>
        </Card>
      </main>
      {/* {error && <ErrorPopup message={error} onClose={() => setError("")} />} */}
    </div>
  );
};

CreateNewCodePage.propTypes = {
  createSign: PropTypes.func.isRequired,
  clearSingleSign: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  signs: state.sign,
  stripe: state.stripe,
  singleSign: state.sign.singleSign,
  saving: state.sign.saving,
  user: state.auth.user,
  codes: state.sign.signs,
});

export default connect(mapStateToProps, {
  createSign,
  clearSingleSign,
  updatePlan,
  setAlert,
})(CreateNewCodePage);
