import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Menu,
  Settings,
  LogOut,
  MessageSquare,
  QrCode,
  Home,
  Building2,
} from "lucide-react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { logout } from "../../actions/auth";

import { Button } from "../ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/Dropdownmenu";

import { prettyPhone } from "../../utlis/pretty";

const AuthMenu = ({ user, isAuthenticated, logout }) => {
  const [phone, setPhone] = useState("555-555-5555");
  useEffect(() => {
    if (user) {
      if (user.phone) {
        setPhone(prettyPhone(user.phone));
      } else {
        setPhone(null);
      }
    }
  }, [user]);

  const logOff = () => {
    logout();
    return <Navigate to="/" />;
  };

  const navigate = useNavigate();
  // Function that redirects to a specified path
  const handleRedirect = (path) => {
    navigate(path);
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <header className="border-b bg-white dark:bg-gray-800 shadow-sm">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <h1
          className="text-2xl font-bold text-blue-600 dark:text-blue-400"
          onClick={() => handleRedirect("/dashboard")}
          style={{ cursor: "pointer" }}
        >
          SimpleSignCalls
        </h1>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-blue-100 dark:hover:bg-gray-700"
            >
              <Menu className="h-5 w-5 text-blue-600 dark:text-blue-400" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <DropdownMenuLabel>
              {phone ? <>{phone}</> : <Link to="/phone">Get Number</Link>}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
              onClick={() => handleRedirect("/dashboard")}
            >
              <Home className="mr-2 h-4 w-4" />
              Dashboard
            </DropdownMenuItem>
            <DropdownMenuItem
              className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
              onClick={() => handleRedirect("/codes")}
            >
              <QrCode className="mr-2 h-4 w-4" />
              Codes
            </DropdownMenuItem>
            <DropdownMenuItem
              className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
              onClick={() => handleRedirect("/leads")}
            >
              <MessageSquare className="mr-2 h-4 w-4" />
              Leads
            </DropdownMenuItem>
            <DropdownMenuItem
              className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
              onClick={() => handleRedirect("/settings")}
            >
              <Settings className="mr-2 h-4 w-4" />
              Settings
            </DropdownMenuItem>
            {user && (user.userLevel === 4 || user.userLevel === 5) && (
              <DropdownMenuItem
                className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
                onClick={() => handleRedirect("/admin")}
              >
                <Building2 className="mr-2 h-4 w-4" />
                Admin Dashboard
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer hover:bg-blue-50 dark:hover:bg-gray-700"
              onClick={() => logOff()}
            >
              <LogOut className="mr-2 h-4 w-4" />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

AuthMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  // authUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(AuthMenu);
