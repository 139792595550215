const filterRecentlyActiveUsers = (users) => {
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  return users.filter((user) => {
    const lastLoginDate = new Date(user.lastLogin); // Assumes `lastLogin` is a valid date string
    return lastLoginDate >= thirtyDaysAgo;
  });
};
const filterActiveUsersWithPlans = (users) => {
  return users.filter(
    (user) => user.plan && user.plan.toLowerCase() !== "free"
  );
};
const filterUsersSignedUpLast30Days = (users) => {
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  return users.filter((user) => {
    const signupDate = new Date(user.timestamp); // Assumes `timestamp` is a valid date string
    return signupDate >= thirtyDaysAgo;
  });
};

const filterLeadsByDays = (leads, days) => {
  const now = new Date();
  const targetDate = new Date();
  targetDate.setDate(now.getDate() - days);

  return leads.filter((lead) => {
    const leadTimestamp = new Date(lead.timestamp); // Assumes `timestamp` is a valid date string
    return leadTimestamp >= targetDate;
  });
};

export {
  filterRecentlyActiveUsers,
  filterActiveUsersWithPlans,
  filterUsersSignedUpLast30Days,
  filterLeadsByDays,
};
