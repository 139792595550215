import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllUsers, getAllMessages, getAllSigns } from "../../actions/admin";
import {
  filterRecentlyActiveUsers,
  filterActiveUsersWithPlans,
  filterUsersSignedUpLast30Days,
  filterLeadsByDays,
} from "../../utlis/filters";
import AuthMenu from "../layout/AuthMenu";
const AdminDash = ({
  admin: { messages, loading, users },
  getAllUsers,
  getAllMessages,
  getAllSigns,
}) => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState({
    activeUsers: 0,
    newUsers: 0,
    recentlyActiveUsers: 0,
    last7DaysLeads: 0,
    last30DaysLeads: 0,
  });
  const [recentUsers, setRecentUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
    getAllMessages();
    getAllSigns();
  }, [getAllUsers, getAllMessages, getAllSigns]);

  useEffect(() => {
    setMetrics({
      activeUsers: filterActiveUsersWithPlans(users).length,
      newUsers: filterUsersSignedUpLast30Days(users).length,
      recentlyActiveUsers: filterRecentlyActiveUsers(users).length,
      last7DaysLeads: filterLeadsByDays(messages, 7).length,
      last30DaysLeads: filterLeadsByDays(messages, 30).length,
    });
    setRecentUsers(filterRecentlyActiveUsers(users));
  }, [users, messages]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <div className="container mx-auto px-6 py-12">
        <h1 className="text-4xl font-bold text-blue-600 mb-6">
          Admin Dashboard
        </h1>
        {loading ? (
          <div className="text-center text-gray-600">Loading metrics...</div>
        ) : (
          <>
            {/* Metrics Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 mb-12">
              {/* New Users */}
              <div
                className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                onClick={() => navigate("/admin/users?filter=new")}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  New Users
                </h2>
                <p className="text-4xl font-bold text-blue-600">
                  {metrics.newUsers || 0}
                </p>
                <p className="text-sm text-muted pt-4">
                  Users signed up in the last 30 days
                </p>
              </div>

              {/* Active Users */}
              <div
                className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                onClick={() => navigate("/admin/users?filter=active")}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Active Users
                </h2>
                <p className="text-4xl font-bold text-blue-600">
                  {metrics.activeUsers || 0}
                </p>
                <p className="text-sm text-muted pt-4">
                  Users with active plans
                </p>
              </div>

              {/* Last 7 Days of Leads */}
              <div
                className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                // onClick={() => navigate("/admin/users?filter=new")}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Last 7 Days
                </h2>
                <p className="text-4xl font-bold text-blue-600">
                  {metrics.last7DaysLeads || 0}
                </p>
                <p className="text-sm text-muted pt-4">
                  All Leads generated in the last 7 days
                </p>
              </div>

              {/* Last 30 Days of Leads */}
              <div
                className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
                // onClick={() => alert("Last 30 Days Leads details coming soon!")}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Last 30 Days
                </h2>
                <p className="text-4xl font-bold text-blue-600">
                  {metrics.last30DaysLeads || 0}
                </p>
                <p className="text-sm text-muted pt-4">
                  All Leads generated in the last 30 days
                </p>
              </div>
            </div>

            {/* Recent Active Users Section */}
            <div className="bg-gray-100 rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">
                  Recent Active Users
                </h2>
                <button
                  onClick={() => navigate("/admin/users")}
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition"
                >
                  View All Users
                </button>
              </div>
              <ul className="space-y-4">
                {recentUsers && recentUsers.length > 0 ? (
                  recentUsers.slice(0, 10).map((user, index) => (
                    <li
                      key={user._id || index}
                      className="p-4 bg-white rounded-lg shadow-md flex justify-between items-center hover:bg-gray-100 transition"
                    >
                      <Link
                        to={`/admin/users/${user._id}`}
                        className="flex justify-between items-center w-full"
                      >
                        <div>
                          <p className="text-lg font-bold text-gray-800">
                            {user.name}
                          </p>
                          <p className="text-sm text-gray-600">{user.email}</p>
                        </div>
                        <span className="text-blue-600 text-sm">
                          {user.lastActive}
                        </span>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="text-center text-gray-600">
                    No recent active users found.
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AdminDash.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  getAllMessages: PropTypes.func.isRequired,
  getAllSigns: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  getAllUsers,
  getAllMessages,
  getAllSigns,
})(AdminDash);
