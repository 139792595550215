import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { marked } from "marked";
import posts from "./posts";

const BlogPost = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const foundPost = posts.find((p) => p.slug === slug);
    setPost(foundPost);
  }, [slug]);

  if (!post) return <p>Loading...</p>;

  return (
    <div className="container mx-auto px-6 py-12">
      <Link
        to="/blog"
        className="inline-block mb-8 text-blue-600 hover:text-blue-800"
      >
        ← Back to Blog
      </Link>
      <h1 className="text-4xl font-bold text-gray-800 mb-4">{post.title}</h1>
      <p className="text-sm text-gray-600 mb-8 ">
        By {post.author} on {new Date(post.publishedDate).toLocaleDateString()}
      </p>
      <div
        className="mt-6 text-gray-700 prose text-lg leading-relaxed lg:prose-xl max-w-none"
        dangerouslySetInnerHTML={{ __html: marked(post.content) }}
      />
    </div>
  );
};

export default BlogPost;
