import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";

import signCallMarketingPrint from "../../img/sign-call-marketing-print.jpg";
import signCallNotifications from "../../img/sign-call-notifications.jpg";
import controlYourSignCalls from "../../img/control-your-sign-calls.jpg";

const FeaturesPage = () => {
  const navigate = useNavigate();
  const features = [
    {
      title: "Call and Text Tracking",
      description:
        "Track every incoming and outgoing call or text to ensure no lead is missed.",
      icon: "📞", // Replace with an actual icon if needed
    },
    {
      title: "Real-Time Notifications",
      description:
        "Receive instant notifications to act on leads as soon as they arrive.",
      icon: "⚡", // Replace with an actual icon if needed
    },
    {
      title: "Zapier Integration",
      description:
        "Automate your workflows by connecting with over 5,000 apps via Zapier.",
      icon: "🔗", // Replace with an actual icon if needed
    },
    {
      title: "Detailed Analytics",
      description:
        "Understand your call and text performance with comprehensive analytics.",
      icon: "📊", // Replace with an actual icon if needed
    },
    {
      title: "CRM Compatibility",
      description:
        "Sync seamlessly with popular CRMs like Brivity and Follow Up Boss.",
      icon: "🔄", // Replace with an actual icon if needed
    },
    {
      title: "Easy Setup",
      description:
        "Get started in minutes with our intuitive onboarding process.",
      icon: "⏱️", // Replace with an actual icon if needed
    },
  ];

  const benefits = [
    {
      title: "Answer When It's An Important Call",
      description:
        "Never wonder if that unknown caller is someone outside your listing calling on your sign or if it is a telamarketer trying to sell you health insurance.",
      image: signCallNotifications,
    },
    {
      title: "Save Time",
      description:
        "You don't have to worry about texting back or reaching back out to leads about simple information on the house and you don't need to put flyers in the a box to give our infomration.",
      image: controlYourSignCalls,
    },
    {
      title: "Control Your Marketing",
      description:
        "Get a phone number with your local area code, customize the code, and customize the automatice response. You are in control.",
      image: signCallMarketingPrint,
    },
  ];

  const successStories = [
    {
      name: "Jason K",
      business: "Golden Acorn Realty",
      story:
        "I used to asnwer ever call because I never knew if it was potential business. Now I have Simple Sign Calls, I don't worry if someone calls or texts on a sign, I know it is taken care of.",
      avatar: "/images/sarah.jpg", // Replace with the path to the avatar image
    },
    {
      name: "Clay W",
      business: "Red Sign",
      story:
        "Our team believes in your listing your lead. It is easy to transfer signs to a new listing, update the message, and then change which agent gets the leads that comes in.",
      avatar: "/images/mike.jpg", // Replace with the path to the avatar image
    },
    {
      name: "Joey S",
      business: "KW",
      story:
        "I look everywhere to find a system that didn't charge me a lot as I look to ramp up my real estate business. Simple Sign Calls solves my problems and for a price I like.",
      avatar: "/images/jessica.jpg", // Replace with the path to the avatar image
    },
  ];

  return (
    <div className="">
      <div className="container mx-auto px-6 py-12">
        <h1 className="text-4xl font-bold text-blue-600 mb-6 text-center">
          Features
        </h1>
        <p className="text-lg text-gray-700 mb-12 text-center">
          Discover the tools and features that make Simple Sign Calls the
          ultimate solution for real estate professionals.
        </p>

        {/* Features Section */}
        <div className="container mx-auto px-6 py-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-6 rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow"
              >
                <div className="text-4xl mb-4 text-blue-600 text-center">
                  {feature.icon}
                </div>
                <h2 className="text-xl font-bold text-gray-800 mb-2 text-center">
                  {feature.title}
                </h2>
                <p className="text-gray-600 text-center">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Key Benefits Section */}
      <section className="py-12">
        <h2 className="text-3xl font-bold text-blue-600 mb-4 text-center">
          Why Choose Simple Sign Calls?
        </h2>
        <div className="space-yx-16">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className={`flex flex-col md:flex-row items-center ${
                index % 2 === 1 ? "md:flex-row-reverse" : ""
              }`}
            >
              {/* Image Section */}
              <div className="w-full md:w-1/2 p-6">
                <img
                  src={benefit.image} // Replace with actual image paths
                  alt={benefit.title}
                  className="rounded-lg shadow-md"
                />
              </div>

              {/* Text Section */}
              <div className="w-full md:w-1/2 p-6 text-center md:text-left">
                <h3 className="text-2xl font-bold text-gray-800 mb-4">
                  {benefit.title}
                </h3>
                <p className="text-lg text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="bg-gray-100 py-12 rounded-lg shadow-md">
        <div className="container mx-auto px-6 py-12">
          <h2 className="text-3xl font-bold text-blue-600 mb-6 text-center">
            Success Stories
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
            {successStories.map((story, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="flex items-center mb-4">
                  {/* <img
                    src={story.avatar}
                    alt={`${story.name}'s avatar`}
                    className="w-16 h-16 rounded-full mr-4"
                  /> */}
                  <div>
                    <h3 className="text-xl font-bold text-gray-800">
                      {story.name}
                    </h3>
                    <p className="text-gray-600">{story.business}</p>
                  </div>
                </div>
                <p className="text-gray-600 italic">"{story.story}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-blue-600 dark:bg-blue-800 py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4 text-white">
            Ready to Simplify Your Real Estate Marketing?
          </h2>
          {/* <p className="text-xl mb-8 text-blue-100">Get started today.</p> */}
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
            {/* <Input
                      placeholder="Enter your email"
                      className="w-full md:w-64 bg-white dark:bg-gray-700"
                    /> */}
            <Button
              size="lg"
              className="w-full md:w-auto bg-white text-blue-600 hover:bg-blue-50"
              onClick={() => {
                navigate("/register");
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeaturesPage;
