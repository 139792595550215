import React from "react";
import { useNavigate } from "react-router-dom";

const ZapierPage = () => {
  const navigate = useNavigate();

  return (
    <div className="rounded-lg shadow-md p-6 bg-gray-100">
      {/* Notice Section */}
      <div className="bg-blue-600 text-white p-4 rounded-md mb-6 text-center">
        <h1 className="text-2xl font-bold mb-2">
          Sign Up to Access Powerful Zaps
        </h1>
        <p className="text-lg">
          To fully utilize Zapier + SimpleSignCalls, you need an account.
        </p>
        <button
          onClick={() => navigate("/register")}
          className="mt-4 bg-white text-blue-600 font-semibold px-6 py-2 rounded shadow hover:bg-blue-100 transition-colors"
        >
          Sign Up Now
        </button>
      </div>

      {/* Zapier Workflow */}
      <div
        className="rounded-lg shadow-md p-6 bg-gray-100"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly transparent white
          border: "1px solid #e5e7eb", // Light grey border
        }}
      >
        <zapier-workflow
          sign-up-email="email_of_your_user@example.com"
          sign-up-first-name="first_name_of_your_user"
          sign-up-last-name="last_name_of_your_user"
          client-id="PLgpVfZni76QkmSKZJZ6ksTWau32pVwrlEhbYm9R"
          theme="light"
          intro-copy-display="show"
          manage-zaps-display="show"
          guess-zap-display="hide"
          template-limit={8}
          zap-create-from-scratch-display="show"
        />
      </div>
    </div>
  );
};

export default ZapierPage;
