import React from "react";
import PropTypes from "prop-types";

export const Modal = ({ title, children, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-3xl p-6">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center focus:outline-none hover:bg-red-600"
        >
          &times;
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-bold mb-4 text-gray-800">{title}</h2>

        {/* Scrollable Modal Content */}
        <div
          className="text-gray-700 overflow-y-auto"
          style={{ maxHeight: "70vh" }} // Adjust height as needed
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};
