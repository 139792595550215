import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const Breadcrumbs = ({ basePath = "/admin" }) => {
  const location = useLocation();

  // Split the pathname into parts
  const paths = location.pathname
    .replace(basePath, "") // Remove base path if provided
    .split("/")
    .filter(Boolean); // Remove empty parts

  return (
    <nav className="text-gray-600 text-sm mb-6">
      <ul className="flex space-x-2">
        <li>
          <Link to={basePath} className="text-blue-600 hover:underline">
            Admin
          </Link>
        </li>
        {paths.map((path, index) => {
          const to = `${basePath}/${paths.slice(0, index + 1).join("/")}`;
          const isLast = index === paths.length - 1;

          return (
            <li key={index} className="flex items-center space-x-2">
              <span>/</span>
              {isLast ? (
                <span className="text-gray-800">
                  {decodeURIComponent(path)}
                </span>
              ) : (
                <Link to={to} className="text-blue-600 hover:underline">
                  {decodeURIComponent(path)}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  basePath: PropTypes.string,
};

export default Breadcrumbs;
