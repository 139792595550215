import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ExternalLink, Save } from "lucide-react";

import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Button } from "../ui/Button";
import { Textarea } from "../ui/TextArea";
import { Modal } from "../ui/Modal";
import { getUserById } from "../../actions/admin";

import { filterLeadsByDays } from "../../utlis/filters";
import { prettyPhone, prettyDate } from "../../utlis/pretty";

import { sendReset } from "../../actions/auth";
import { updateUserById } from "../../actions/admin";

import AuthMenu from "../layout/AuthMenu";
import Breadcrumbs from "./BreadCrumbs";

const UserDetails = ({
  admin: { users, messages, signs },
  loading,
  getUserById,
  sendReset,
  updateUserById,
}) => {
  const { id } = useParams();
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    phone: "5555555555",
    callScript: "",
    notification: "",
    timestamp: new Date(),
    lastLogin: new Date(),
    subscription: "",
  });
  const [stats, setStats] = useState({
    codesUsed: 0,
    messagesLast30Days: 0,
  });
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [codes, setCodes] = useState([]);
  const [userMessages, setUserMessages] = useState([]);

  useEffect(() => {
    const codes = signs.filter((sign) => sign.user === id);
    setCodes(codes);
  }, [signs, id]);

  useEffect(() => {
    const foundMessages = messages.filter((message) => message.user === id);
    setUserMessages(foundMessages);
  }, [messages, id]);

  useEffect(() => {
    const selectedUser = users.find((user) => user._id === id);
    if (selectedUser) {
      setUserData(selectedUser); // Populate user data for editing
      setStats(calculateStats(selectedUser, messages, signs));
    } else {
      getUserById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, users, getUserById, messages, signs]);

  const calculateStats = (user, messages, signs) => {
    const codesUsed = signs.filter((sign) => sign.user === id).length;

    // Filter messages related to the user for the last 30 days
    const userMessages = messages.filter((message) => message.user === id);
    const messagesLast30Days = filterLeadsByDays(userMessages, 30).length;

    return {
      codesUsed,
      messagesLast30Days,
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setUserData({ ...userData, phone: phone });
  };

  const toggleEditing = () => setEditing((prev) => !prev);

  const saveChanges = () => {
    updateUserById({
      id: id,
      email: userData.email,
      forwardPhone: userData.forwardPhone,
      timeZone: userData.timeZone,
      notification: userData.notification,
      callScript: userData.callScript,
    });
    // Add logic to save changes to the server
    toggleEditing();
  };

  const sendPasswordReset = (e) => {
    e.preventDefault();
    sendReset({ email: userData.email });
  };

  const handleModalOpen = (type) => {
    if (type === "codes") {
      setModalContent({
        title: "User Codes",
        data: userData.codes || [],
        type: "codes",
      });
    } else if (type === "messages") {
      const userMessages = messages.filter(
        (message) => message.userId === userData._id
      );
      const last30DaysMessages = filterLeadsByDays(userMessages, 30);
      setModalContent({
        title: "Messages in Last 30 Days",
        data: last30DaysMessages,
        type: "messages",
      });
    }
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  if (loading || !userData) {
    return (
      <div className="text-center text-gray-600">Loading user details...</div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />
      <div className="container mx-auto px-6 py-12">
        <Breadcrumbs basePath="/admin" />
        <h1 className="text-4xl font-bold text-blue-600 mb-6">
          User Details: {userData.name}
        </h1>

        {/* User Stats */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
          {/* Codes Used */}
          <div
            className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
            onClick={() => handleModalOpen("codes")}
          >
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Codes Used
            </h2>
            <p className="text-4xl font-bold text-blue-600">
              {stats.codesUsed}
            </p>
          </div>

          {/* Messages in Last 30 Days */}
          <div
            className="p-6 bg-white rounded-lg shadow-md text-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
            onClick={() => handleModalOpen("messages")}
          >
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Messages in Last 30 Days
            </h2>
            <p className="text-4xl font-bold text-blue-600">
              {stats.messagesLast30Days}
            </p>
          </div>
        </div>

        {/* User Information */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">
              User Information
            </h2>
            <button
              onClick={toggleEditing}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition"
            >
              {editing ? "Cancel" : "Edit"}
            </button>
          </div>
          {editing ? (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={userData.email}
                  onChange={handleInputChange}
                />
              </div>

              {/* Phone Field */}
              <div className="space-y-2">
                <Label htmlFor="forwardPhone">Phone</Label>
                <Input
                  id="forwardPhone"
                  name="forwardPhone"
                  type="tel"
                  placeholder="555-123-4567"
                  value={userData.forwardPhone}
                  onChange={formatPhone}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="callScript">Call Script</Label>
                <Textarea
                  id="callScript"
                  name="callScript"
                  className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                  rows="4"
                  placeholder="Enter the call script to be read when someone calls."
                  value={userData.callScript}
                  onChange={handleInputChange}
                ></Textarea>
              </div>

              <div className="space-y-2">
                <Label htmlFor="callScript">Notification</Label>
                <Textarea
                  id="notification"
                  name="noti"
                  className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                  rows="4"
                  placeholder="Enter the call script to be read when someone calls."
                  value={userData.notification}
                  onChange={handleInputChange}
                ></Textarea>
              </div>
              {/* Add other editable fields as needed */}
              <Button onClick={saveChanges} className="w-full">
                <Save className="mr-2 h-4 w-4" /> Save
              </Button>
            </div>
          ) : (
            <div className="space-y-2">
              <ol>
                <li>
                  <strong>Email:</strong> {userData.email}
                </li>
                <li>
                  <strong>User's Phone:</strong>{" "}
                  {userData.forwardPhone
                    ? prettyPhone(userData.forwardPhone)
                    : "N/A"}
                </li>
                <li>
                  <strong>Call Script:</strong> {userData.callScript}
                </li>
                <li>
                  <strong>Notification:</strong> {userData.notification}
                </li>
              </ol>
            </div>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <Button onClick={sendPasswordReset} className="w-full">
            Send Password Reset
          </Button>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <p>
            <strong>User Signed Up:</strong> {prettyDate(userData.timestamp)}
          </p>
          <p>
            <strong>Last Login:</strong> {prettyDate(userData.lastLogin)}
          </p>
        </div>

        {/* Twilio Section */}
        {userData.phone && (
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-800">
                Twilio Information
              </h2>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition"
                onClick={() => window.open(`https://www.twilio.com/`, "_blank")}
              >
                <ExternalLink className="mr-2 h-4 w-4" />
              </button>
            </div>
            <p>
              <strong>Twilio Phone Number:</strong>{" "}
              {prettyPhone(userData.phone)}
            </p>
          </div>
        )}

        {/* Stripe Section */}
        {userData.subscription && (
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-800">
                Stripe Information
              </h2>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition"
                onClick={() =>
                  window.open(
                    `https://dashboard.stripe.com/${userData.subscription}`,
                    "_blank"
                  )
                }
              >
                <ExternalLink className="mr-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* Modal */}
      {isModalOpen && modalContent && (
        <Modal title={modalContent.title} onClose={handleModalClose}>
          {modalContent.type === "codes" ? (
            <ul className="space-y-2">
              {codes.map((item, index) => (
                <li
                  key={index}
                  className="p-4 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition"
                >
                  <details>
                    <summary className="font-bold">Code: {item.code}</summary>
                    <div className="text-gray-700">
                      <p>
                        <strong>Message:</strong> {item.message}
                      </p>
                    </div>
                  </details>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="space-y-4">
              {userMessages.map((item, index) => (
                <li
                  key={index}
                  className="p-4 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition"
                >
                  <details>
                    <summary className="font-bold">
                      Message: {item.code}
                    </summary>
                    <div className="text-gray-700">
                      <p>
                        <strong>Date:</strong> {prettyDate(item.timestamp)}
                      </p>
                      <p>
                        <strong>Type:</strong> {item.type}
                      </p>
                      <p>
                        <strong>Message:</strong> {item.caller}
                      </p>
                      <p>
                        <strong>Message:</strong> {prettyPhone(item.from)}
                      </p>
                    </div>
                  </details>
                </li>
              ))}
            </ul>
          )}
        </Modal>
      )}
    </div>
  );
};

UserDetails.propTypes = {
  admin: PropTypes.object.isRequired,
  getUserById: PropTypes.func.isRequired,
  sendReset: PropTypes.func.isRequired,
  updateUserById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  loading: state.admin.loading,
});

export default connect(mapStateToProps, {
  getUserById,
  sendReset,
  updateUserById,
})(UserDetails);
