import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { everyPlan as plans } from "../../utlis/plans";

import AuthMenu from "../layout/AuthMenu";
import Breadcrumbs from "./BreadCrumbs";

const UserList = ({ users }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users || []);
  const [activeFilter, setActiveFilter] = useState("all"); // Default filter

  // Extract filter from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get("filter") || "all";
    setActiveFilter(filter);
  }, [location.search]);

  // Apply filters when users or activeFilter change
  useEffect(() => {
    let filtered = users;

    // Filter by predefined filters
    if (activeFilter === "new") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      filtered = users.filter(
        (user) => new Date(user.timestamp) >= sevenDaysAgo
      );
    } else if (activeFilter === "active") {
      filtered = users.filter((user) => user.plan); // Has a subscription
    }

    // Apply search term
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (user) =>
          user.email.toLowerCase().includes(term) ||
          (user.phone && user.phone.toLowerCase().includes(term))
      );
    }

    setFilteredUsers(filtered);
  }, [users, activeFilter, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (filter) => {
    navigate(`?filter=${filter}`);
  };

  // Get plan name by filtering the plans utility
  const getPlanName = (planId) => {
    const plan = plans.find((p) => p.plan === planId);
    return plan ? plan.name : "No Plan";
  };

  if (!users || users.length === 0) {
    return <div className="text-center text-gray-600">No users available.</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />
      <div className="container mx-auto px-6 py-12">
        <Breadcrumbs basePath="/admin" />
        <h1 className="text-4xl font-bold text-blue-600 mb-6">User List</h1>

        {/* Filters */}
        <div className="flex space-x-4 mb-6">
          <button
            className={`py-2 px-4 rounded-lg ${
              activeFilter === "all"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleFilterChange("all")}
          >
            All Users
          </button>
          <button
            className={`py-2 px-4 rounded-lg ${
              activeFilter === "new"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleFilterChange("new")}
          >
            New Users
          </button>
          <button
            className={`py-2 px-4 rounded-lg ${
              activeFilter === "active"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleFilterChange("active")}
          >
            Active Users
          </button>
        </div>

        {/* Search Input */}
        <div className="mb-6">
          <Label htmlFor="search" className="block text-sm font-medium mb-2">
            Search Users
          </Label>
          <Input
            id="search"
            type="text"
            placeholder="Search by email, or phone"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {/* User List */}
        <ul className="space-y-4">
          {filteredUsers.map((user) => (
            <li
              key={user._id}
              className="p-4 bg-white rounded-lg shadow-md hover:bg-gray-100 transition"
              onClick={() => navigate(`/admin/users/${user._id}`)}
              style={{ cursor: "pointer" }}
            >
              <h3 className="text-xl font-bold">{user.email}</h3>
              <p className="text-sm text-gray-600">
                <strong>Phone:</strong> {user.phone || "Not Provided"}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Plan:</strong> {getPlanName(user.plan)}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

UserList.propTypes = {
  //   users: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.admin.users, // Adjust based on your Redux structure
});

export default connect(mapStateToProps)(UserList);
