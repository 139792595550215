import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import MainLanding from "./components/landing/MainLanding";

import Alert from "./components/layout/Alert";
import PrivateRoute from "./components/routing/PrivateRoute";
import PublicRoute from "./components/routing/PublicRoute";
import ScrollToTop from "./utlis/scrollToTop";

import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utlis/setAuthToken";

import "./App.css";
import GoogleAnalytics from "./components/GoogleAnalytics";

import Terms from "./components/legal/Terms";
import Privacy from "./components/legal/Privacy";

import LoginPage from "./components/user/Login";
import ForgotPasswordPage from "./components/user/ForgotPassword";
import ResetPasswordPage from "./components/user/ResetPassword";
import RegisterPage from "./components/user/Register";
import LandingPage from "./components/landing/LandingPage";
import BlankPage from "./components/layout/BlankPage";
import IntegrationsPage from "./components/landing/IntegrationsPage";
import ZapierPage from "./components/landing/ZapierPage";
import ContactUsPage from "./components/settings/ContactUs";
import Blog from "./components/blog/Blog";
import BlogPost from "./components/blog/BlogPost";

import SelectPhoneNumberPage from "./components/SelectPhone";
import FirstCode from "./components/codes/FirstCode";
import SetUpFirstCode from "./components/codes/SetUpFirstCode";
import BillingSetupPage from "./components/settings/Billing";
import Dashboard from "./components/Dashboard";
import Code from "./components/codes/Codes";
import CodeDetails from "./components/codes/CodeDetails";
import CreateNewCodePage from "./components/codes/NewCode";
import LeadsPage from "./components/leads/Leads";
import LeadDetails from "./components/leads/LeadDetails";
import SettingsPage from "./components/settings/Settings";
import FeaturesPage from "./components/landing/FeaturesPage";
import PricingPage from "./components/landing/PricingPage";

import AdminRoute from "./components/routing/AdminRoute";
import AdminDash from "./components/admin/AdminDash";
import UserDetails from "./components/admin/UserDetails";
import UserList from "./components/admin/UserList";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Alert />
          <ScrollToTop />
          <Routes>
            <Route
              path=""
              element={
                <PublicRoute title="Simple Sign Calls">
                  <BlankPage fullWidth="true">
                    <LandingPage />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="integrations"
              element={
                <PublicRoute title="Integrations">
                  <BlankPage>
                    <IntegrationsPage />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="integrations/zapier"
              element={
                <PublicRoute title="Explore Zapier Integrations">
                  <BlankPage>
                    <ZapierPage />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="features"
              element={
                <PublicRoute title="Simple Sign Calls Features">
                  <BlankPage fullWidth="true">
                    <FeaturesPage />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="pricing"
              element={
                <PublicRoute title="Simple Sign Calls Pricing">
                  <BlankPage>
                    <PricingPage />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="register"
              element={
                <PublicRoute title="Register">
                  <RegisterPage />
                </PublicRoute>
              }
            />
            <Route
              path="login"
              element={
                <PublicRoute title="Login">
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="forgot-password"
              element={
                <PublicRoute title="Forgot Password">
                  <ForgotPasswordPage />
                </PublicRoute>
              }
            />
            <Route
              path="reset-password"
              element={
                <PublicRoute title="Reset Password">
                  <ResetPasswordPage />
                </PublicRoute>
              }
            />
            <Route
              path="terms"
              element={
                <PublicRoute title="Terms & Conditions">
                  <BlankPage>
                    <Terms />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="privacy"
              element={
                <PublicRoute title="Privacy Policy">
                  <BlankPage>
                    <Privacy />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="contact-us"
              element={
                <PublicRoute title="Contact Us">
                  <BlankPage>
                    <ContactUsPage />
                  </BlankPage>
                </PublicRoute>
              }
            />

            <Route
              path="blog"
              element={
                <PublicRoute title="Blog">
                  <BlankPage>
                    <Blog />
                  </BlankPage>
                </PublicRoute>
              }
            />
            <Route
              path="blog/:slug"
              element={
                <PublicRoute title="Blog Post">
                  <BlankPage callToAction={true}>
                    <BlogPost />
                  </BlankPage>
                </PublicRoute>
              }
            />

            {/* ------------- PRIVATE ROUTES ------------- */}

            <Route
              path="dashboard"
              element={
                <PrivateRoute title="Dashboard">
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="settings"
              element={
                <PrivateRoute title="Settings">
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="feedback"
              element={
                <PrivateRoute title="Feedback">
                  <ContactUsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="codes"
              element={
                <PrivateRoute title="Codes">
                  <Code />
                </PrivateRoute>
              }
            />
            <Route
              path="codes/new"
              element={
                <PrivateRoute title="Create a Code">
                  <CreateNewCodePage />
                </PrivateRoute>
              }
            />
            <Route
              path="codes/:id"
              element={
                <PrivateRoute title="Code Details">
                  <CodeDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="leads"
              element={
                <PrivateRoute title="Leads">
                  <LeadsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="leads/:id"
              element={
                <PrivateRoute title="Lead Details">
                  <LeadDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="phone"
              element={
                <PrivateRoute title="Get Phone Number">
                  <SelectPhoneNumberPage />
                </PrivateRoute>
              }
            />
            <Route
              path="first-code"
              element={
                <PrivateRoute title="Set Your First Code">
                  <FirstCode />
                </PrivateRoute>
              }
            />
            <Route
              path="creating-account"
              element={
                <PrivateRoute title="Setting Up Your Account">
                  <SetUpFirstCode />
                </PrivateRoute>
              }
            />
            <Route
              path="billing"
              element={
                <PrivateRoute title="Set Your Payment">
                  <BillingSetupPage />
                </PrivateRoute>
              }
            />

            {/* ----------------- Admin Pages ------------------  */}

            <Route
              path="admin"
              element={
                <AdminRoute title="Admin">
                  <AdminDash />
                </AdminRoute>
              }
            />
            <Route
              path="admin/users"
              element={
                <AdminRoute title="Admin">
                  <UserList />
                </AdminRoute>
              }
            />
            <Route
              path="admin/users/:id"
              element={
                <AdminRoute title="Admin">
                  <UserDetails />
                </AdminRoute>
              }
            />
            {/*}
              <Route
                path="admin/user/add"
                element={
                  <PrivateRoute title="Admin">
                    <AddUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="admin/user/:id"
                element={
                  <PrivateRoute title="Admin User Info">
                    <UserInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path="*"
                element={
                  <PublicRoute title="Oops">
                    <NotFound />
                  </PublicRoute>
                }
              /> */}
          </Routes>
          {/* </section> */}
          <GoogleAnalytics />
          {/* </div> */}
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
