import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { plans } from "../../utlis/plans"; // Import the plans array

const PricingPage = () => {
  const navigate = useNavigate();

  // State for the slider
  const [selectedCodes, setSelectedCodes] = useState(5);

  // Calculate the price dynamically based on selectedCodes
  const getPriceForCodes = () => {
    // Find the plan that matches the number of codes
    const plan = plans.find((plan) => selectedCodes <= plan.codes);
    return plan ? `${plan.price}/month` : "Contact Us";
  };

  return (
    <div className="container mx-auto px-6 py-12">
      {/* Header Section */}
      <section className="text-center mb-12">
        <h1 className="text-4xl font-bold text-blue-600 mb-4">
          Simple, Scalable Pricing
        </h1>
        <p className="text-lg text-gray-700">
          Start small and let Simple Sign Calls grow with your business. Pricing
          adjusts based on the number of codes you use.
        </p>
      </section>

      {/* Pricing Slider Section */}
      <section className="bg-gray-100 py-12 rounded-lg shadow-md mb-12">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Select the Number of Codes
          </h2>
          <p className="text-gray-600 mb-8">
            Adjust the slider to see your monthly cost based on the number of
            codes you need.
          </p>

          {/* Slider */}
          <div className="flex flex-col items-center">
            <input
              type="range"
              min="1"
              max="100"
              value={selectedCodes}
              onChange={(e) => setSelectedCodes(Number(e.target.value))}
              className="w-3/4 md:w-1/2"
            />
            <div className="text-blue-600 font-bold text-xl mt-4">
              {selectedCodes} Codes
            </div>
            <div className="text-gray-800 text-3xl font-bold mt-2">
              ${getPriceForCodes()}
            </div>
          </div>
        </div>
      </section>

      {/* How Pricing Works Section */}
      <section className="py-12">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          How Our Pricing Works
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold text-blue-600 mb-2">
              Start Small
            </h3>
            <p className="text-gray-600">
              Use up to 5 codes for just $15/month—perfect for new agents or
              small teams.
            </p>
          </div>
          <div className="text-center p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold text-blue-600 mb-2">
              Grow Your Business
            </h3>
            <p className="text-gray-600">
              Need more codes? Our pricing scales to fit your growing needs.
            </p>
          </div>
          <div className="text-center p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold text-blue-600 mb-2">
              No Hidden Fees
            </h3>
            <p className="text-gray-600">
              Pay only for what you use—no setup fees or surprises.
            </p>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-blue-600 py-16 text-center text-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4">
          Ready to Simplify Your Sign Call Management?
        </h2>
        <p className="text-lg mb-8">
          Join hundreds of agents already saving time and closing more deals.
        </p>
        <button
          onClick={() => navigate("/register")}
          className="bg-white text-blue-600 font-bold py-3 px-8 rounded-lg hover:bg-blue-100 transition"
        >
          Sign Up Now
        </button>
      </section>
    </div>
  );
};

export default PricingPage;
