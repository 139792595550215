//SSC to Slack
//SSC to Sheets
//SSC to Brivity
//SSC to Follow Up Boss
//SSC to Mailchimp
import React from "react";
import { Button } from "../ui/Button";
import { useNavigate } from "react-router-dom";

const integrations = [
  {
    name: "Google Sheets",
    description:
      "Log every call and text into a spreadsheet to keep your team updated.",
    useCase: "Track leads and assign follow-ups from a single sheet.",
    logo: "/images/google-sheets.png", // Replace with your actual image path
    zapLink:
      "https://api.zapier.com/v1/embed/simplesigncalls/create?steps[0][app]=SimpleSignCallsCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=GoogleSheetsV2CLIAPI@latest&steps[1][action]=add_row",
  },
  {
    name: "Slack",
    description:
      "Send instant notifications to your team whenever a call or text comes in.",
    useCase: "Alert agents of new leads in real-time.",
    logo: "/images/slack.png", // Replace with your actual image path
    zapLink:
      "https://api.zapier.com/v1/embed/simplesigncalls/create?steps[0][app]=SimpleSignCallsCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=SlackCLIAPI@latest&steps[1][action]=channel_message&steps[1][params][as_bot]=true&steps[1][params][username]=SimpleSignCalls",
  },
  {
    name: "Mailchimp",
    description:
      "Automatically add leads to email campaigns to keep prospects engaged.",
    useCase: "Nurture leads with drip campaigns tailored to their needs.",
    logo: "/images/mailchimp.png", // Replace with your actual image path
    zapLink:
      "https://api.zapier.com/v1/embed/simplesigncalls/create?steps[0][app]=SimpleSignCallsCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=MailchimpCLIAPI@latest&steps[1][action]=memberCreate&steps[1][params][tags]=SimpleSignCalls",
  },
  {
    name: "Brivity",
    description: "Sync leads directly into Brivity for streamlined follow-up.",
    useCase: "Reduce manual data entry by automating lead imports.",
    logo: "/images/brivity.png", // Replace with your actual image path
    zapLink:
      "https://api.zapier.com/v1/embed/simplesigncalls/create?steps[0][app]=SimpleSignCallsCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=BrivityCLIAPI@latest&steps[1][action]=create_lead&steps[1][params][source]=SimpleSignCalls",
  },
  {
    name: "Follow Up Boss",
    description:
      "Push call and text data into Follow Up Boss to ensure accurate records.",
    useCase: "Keep your CRM updated without lifting a finger.",
    logo: "/images/follow-up-boss.png", // Replace with your actual image path
    zapLink:
      "https://api.zapier.com/v1/embed/simplesigncalls/create?steps[0][app]=SimpleSignCallsCLIAPI@latest&steps[0][action]=new_lead&steps[1][app]=FollowUpBossCLIAPI@latest&steps[1][action]=create_contact&steps[1][params][person_source]=SimpleSignCalls",
  },
  {
    name: "See The Others",
    description: "Check out all the other possibilities on the Zapier page",
    useCase: "→",
    zapLink: "https://www.simplesigncalls.com/integrations/zapier",
  },
];

const IntegrationsPage = () => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto px-6 py-12">
      {/* Hero Section */}
      <section className="text-center mb-12">
        <h1 className="text-4xl font-bold text-blue-600 mb-4">
          Seamless Integrations
        </h1>
        <p className="text-lg text-gray-700">
          Connect Simple Sign Calls to your favorite tools and automate
          workflows for effortless lead management.
        </p>
        <Button
          className="mt-6 bg-blue-600 text-white hover:bg-blue-700"
          onClick={() => navigate("/register")}
        >
          Get Started
        </Button>
      </section>

      {/* Zapier Section */}
      <section className="bg-gray-50 p-8 rounded-lg shadow-md mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Zapier Integration
        </h2>
        <p className="text-gray-600 mb-4">
          Zapier allows Simple Sign Calls to connect with over 5,000 apps, so
          you can build workflows tailored to your needs.
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>
            Automatically log all calls and texts into Google Sheets for easy
            tracking.
          </li>
          <li>Send notifications to Slack whenever you receive a new lead.</li>
          <li>
            Add new leads to Mailchimp to start email marketing campaigns.
          </li>
          <li>
            Push call and text data into your CRM like Brivity or Follow Up
            Boss.
          </li>
        </ul>
        <Button
          className="bg-blue-600 text-white hover:bg-blue-700"
          onClick={() => navigate("/integrations/zapier")}
        >
          Explore Zapier Integrations
        </Button>
      </section>

      {/* Featured Integrations Section */}
      <section className="mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Featured Integrations
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {integrations.map((integration) => (
            <div
              key={integration.name}
              onClick={() => {
                if (integration.name === "See The Others") {
                  navigate("/integrations/zapier"); // Internal navigation for "See The Others"
                }
              }}
              className={`group flex flex-col justify-between p-6 rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl cursor-pointer ${
                integration.name === "See The Others"
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-white text-gray-800 hover:bg-gray-100"
              }`}
            >
              {integration.name !== "See The Others" ? (
                <a
                  href={integration.zapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col justify-between h-full"
                >
                  {/* Display the integration logo and content */}
                  <div>
                    {/* <img
                      src={integration.logo}
                      alt={`${integration.name} logo`}
                      className="h-16 w-auto mx-auto mb-4"
                    /> */}
                    <h3 className="text-xl font-semibold mb-2 text-center group-hover:text-blue-600 transition-colors duration-300">
                      {integration.name}
                    </h3>
                    <p className="text-gray-600 text-sm mb-2 text-center">
                      {integration.description}
                    </p>
                  </div>
                  <p className="text-xs italic text-gray-500 text-center">
                    {integration.useCase}
                  </p>
                </a>
              ) : (
                // Special Styling for "See The Others"
                <div>
                  <h3 className="text-2xl font-bold mb-4 text-center">
                    {integration.description}
                  </h3>
                  <p className="text-lg font-medium text-center">
                    {integration.useCase}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Benefits Section */}
      <section className="text-center bg-gray-50 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Why Integrate with Simple Sign Calls?
        </h2>
        <ul className="list-none text-gray-700 mx-auto max-w-2xl">
          <li>Save time with automated workflows.</li>
          <li>Reduce manual data entry and minimize errors.</li>
          <li>Stay on top of leads with instant notifications.</li>
          <li>Ensure all your tools are working together seamlessly.</li>
        </ul>
        <Button
          className="mt-6 bg-blue-600 text-white hover:bg-blue-700"
          onClick={() => navigate("/register")}
        >
          Start Automating Your Workflow
        </Button>
      </section>
    </div>
  );
};

export default IntegrationsPage;
